import React from 'react';
import './landing.scss';
import {Logo, SocialMediaLink} from '../../components';

const Landing = () => {
  return (
    <div className="landing">
      <Logo title="AYooLA"/>
      <div className="landing__social-media-links">
        <SocialMediaLink siteName="gitlab" profileURL="https://gitlab.com/ayoolaao" />
        <SocialMediaLink siteName="github" profileURL="https://github.com/ayoolaao" />
        <SocialMediaLink siteName="linkedIn" profileURL="https://www.linkedin.com/in/abimbolaao/" />
        <SocialMediaLink siteName="instagram" profileURL="https://www.instagram.com/ayoolaao/" />
        {/*<SocialMediaLink />*/}
      </div>
    </div>
  );
};

export default Landing;

import React from 'react';
import './socialMediaLink.scss';
import PropTypes from 'prop-types';

import { FaDizzy, FaFacebook, FaGithub, FaGitlab, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';

const iconFactory = (iconName, color='#ffffff', size='2.5em') => {
  switch (iconName && iconName.toLowerCase()) {
    case 'gitlab':
      return (<FaGitlab style={{verticalAlign: 'middle', fontSize: size, color: '#fca326'}} />);
    case 'github':
      return (<FaGithub style={{verticalAlign: 'middle', fontSize: size, color: '#000000'}} />);
    case 'linkedin':
      return (<FaLinkedin style={{verticalAlign: 'middle', fontSize: size, color: '#0e76a8'}} />);
    case 'facebook':
      return (<FaFacebook style={{verticalAlign: 'middle', fontSize: size, color: '#3b5998'}} />);
    case 'instagram':
      return (<FaInstagram style={{verticalAlign: 'middle', fontSize: size, color: '#e1306c'}} />);
    case 'twitter':
      return (<FaTwitter style={{verticalAlign: 'middle', fontSize: size, color: '#1da1f2'}} />);
    default:
      return (<FaDizzy style={{verticalAlign: 'middle', fontSize: size, color: '#ea4335'}} />);
  }
};

const openLink = linkUrl => window.open(linkUrl, '_blank', 'noopener noreferrer');

const SocialMediaLink = ({profileURL, siteName, iconColor, iconSize}) => {
  return (
    <div className="social-media-link" onClick={() => openLink(profileURL)}>
      {iconFactory(siteName, iconColor, iconSize)}
    </div>
  );
};

SocialMediaLink.propTypes = {
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  profileURL: PropTypes.string,
  siteName: PropTypes.string,
};

export default SocialMediaLink;

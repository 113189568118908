import React from 'react';
import './app.sass';
import {Landing} from './routes';

function App() {
  return (
    <div className="app">
      <Landing/>
      <div className="dev-mode">
        Under Construction
      </div>
      {new Array(240).fill(0).map(fly => (<div className="firefly"></div>))}
    </div>
  );
}

export default App;

import React from 'react';
import './logo.scss';
import PropTypes from 'prop-types';

const Logo = ({title}) => {
  return (
    <div className="logo">
      <span className="logo-bracket">&lt;</span>
      <span className="logo-text">{title}</span>
      <span className="logo-bracket">/&gt;</span>
    </div>
  );
};

Logo.propTypes = {
  title: PropTypes.string.isRequired,
};

export default Logo;
